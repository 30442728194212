const $nav = document.getElementById("nav");
const $menuButton = document.getElementById("menu-button");
const $navItems = document.querySelectorAll(".nav--item");
let isOpened = false;

document.addEventListener("click", function (e) {
  closeMenu();
});

$nav.addEventListener("click", function (e) {
  e.stopPropagation();
});

$navItems.forEach(function (item) {
  item.addEventListener("click", function (e) {
    e.stopPropagation();
    closeMenu();
  });
});

$menuButton.addEventListener("click", function (e) {
  e.stopPropagation();
  if (isOpened) {
    closeMenu();
  } else {
    openMenu();
  }
});

function openMenu() {
  $nav.classList.toggle("opened", true);
  isOpened = true;
}

function closeMenu() {
  $nav.classList.toggle("opened", false);
  isOpened = false;
}
